<template>
  <div class="page-container-main page-container-main--grid" style="margin:20px">
    <div class="detail-card colspan-10">
      <div class="markdown-body" v-html="htmlMD"></div>
    </div>
  </div>
</template>

<script>
import 'github-markdown-css'

export default {
    name: 'readme',
    data () {
      return {
        htmlMD: ''
      }
    },
    mounted() {
      this.htmlMD = require('@/assets/md/versions.md')
    }
};
</script>